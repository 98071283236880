<template>
    <div>
        <table>
            <tr v-for="(checkboxObject, index) in cycleArray" :key="index">
                <td>
                    <input type="checkbox" v-model="cycleArray[index].isChecked" class="checkbox-style">
                </td>
                <td>
                    <label :class="cycleArray[index].isChecked ? 'title-checked-style' : 'title-style'">{{
                        cycleArray[index].title }}</label>
                </td>
                <td>
                    <label class="description-style">{{ cycleArray[index].description }}</label>
                </td>
            </tr>
        </table>
        <p style="color:white;" class="checkbox-style">Mouse sensitivity</p>
        <input type="range" v-model="mouseSliderVariable" class="ticks checkbox-style" min="0.20" max="20.00" step="0.1"
            style="width: 55%;" />
        <input type="text" v-model="mouseSliderVariable" style="margin-left: 2%; width: 2em; color:white;" />
    </div>
</template>

<script>
export default {
    name: "MouseMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            mouseSliderVariable: 3.0,
            cycleArray: [
                {
                    isChecked: false,
                    title: "Reverse mouse",
                    description: "Reverse mouse up-down axis"
                },
                {
                    isChecked: false,
                    title: "Mouse look",
                    description: "Use the mouse to look around"
                },
                {
                    isChecked: false,
                    title: "Mouse filter",
                    description: "Smooth out mouse movement"
                },
                {
                    isChecked: false,
                    title: "Joystick",
                    description: "Enable the joystick"
                },
                {
                    isChecked: false,
                    title: "Joystick look",
                    description: "Use the joystick to look around"
                },
                {
                    isChecked: false,
                    title: "Auto-Aim",
                    description: "Aims at enemies automatically"
                },
                {
                    isChecked: false,
                    title: "Raw mouse input",
                    description: "Directly access mouse data"
                },
            ]
        };
    },
    methods: {}
}
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

.checkbox-style {
    margin-left: 3.2em;
    text-align: left;
}

.title-style {
    color: white;
    text-align: left;
}

.title-checked-style {
    color: #bcaf4f;
    text-align: left;
}

.description-style {
    color: #9DA792;
    margin-left: 2em;
    text-align: left;
    font-size: 0.9em;
    width: 33.3%;
}
</style>