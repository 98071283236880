<template>
  <div id="app">
    <div id="main-settings" class="window no-resize draggable" name="Options"
      :style="{ top: position.y + 'px', left: position.x + 'px' }" @mousedown="startDrag" @mousemove="drag"
      @mouseup="stopDrag">
      <div class="box" style="width: 41.3em;">
        <nav style="width: fit-content;">
          <li v-for="button in buttons" :key="button">
            <button :class="{ active: activeMenu === button }" @click="menuButtonPressed(button)">{{ button }}</button>
          </li>
        </nav>
        <component :is="menuToShow" />
      </div>
      <button class="button-align-right">Apply</button>
      <button class="button-align-right">Cancel</button>
      <button class="button-align-right">OK</button>
    </div>
  </div>
</template>

<script>
import KeyboardMenu from "./Components/KeyboardMenu.vue";
import MouseMenu from "./Components/MouseMenu.vue";
import AudioMenu from "./Components/AudioMenu.vue";
import VideoMenu from "./Components/VideoMenu.vue";
import VoiceMenu from "./Components/VoiceMenu.vue";
import LockMenu from "./Components/LockMenu.vue";
import MultiplayerMenu from "./Components/MultiplayerMenu.vue";
export default {
  name: "App",
  components: {
    KeyboardMenu,
    MouseMenu,
    AudioMenu,
    VideoMenu,
    VoiceMenu,
    LockMenu,
    MultiplayerMenu,
  },
  computed: {
    menuToShow() {
      return `${this.activeMenu}Menu`;
    },
  },
  data() {
    return {
      activeMenu: "Keyboard",
      buttons: [
        "Keyboard",
        "Mouse",
        "Audio",
        "Video",
        "Voice",
        "Lock",
        "Multiplayer",
      ],
      isDragging: false,
      position: { x: 0, y: 0 },
      mousePosition: { x: 0, y: 0 },
      elementPosition: { x: 0, y: 0 },
    };
  },
  mounted() {
    this.activeMenu = this.buttons[0];
  },
  methods: {
    menuButtonPressed(value) {
      this.activeMenu = value;
      console.log("Trykkede på knap", value);
    },
    startDrag(event) {
      this.mousePosition = { x: event.clientX, y: event.clientY };
      this.elementPosition = { x: this.position.x, y: this.position.y };
      if (this.mousePosition.y - this.elementPosition.y >= 50) {
        return;
      }
      this.isDragging = true;
    },
    drag(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.mousePosition.x;
        const deltaY = event.clientY - this.mousePosition.y;
        this.position.x = this.elementPosition.x + deltaX;
        this.position.y = this.elementPosition.y + deltaY;
      }
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */

#app {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#main-settings {
  display: inline-block;
}

.button-align-right {
  float: right;
  margin-left: 2%;
}

.draggable {
  position: absolute;
  cursor: move;
}

.active {
  color: #bcaf4f;
}

button {
  outline: none;
}
</style>
