<template>
    <div class="flexboxrow" style="position: relative;">
        <div style="width: 33%;"> <!--col1-->
            <p style="color:white;margin-left: 0em;">Player name</p>
            <input style="width: 80%; color:white;" type="text" placeholder="Enter name" v-model="playerName"
                @keydown.enter="storePlayerName">

            <p style="color:white;margin-left: 0em;">Player model</p>
            <select v-model="playerModel" style="color:white;">
                <option v-for="(model, i) in playerModels" :key="i" :value="model">{{ model }}</option>
            </select>

            <p style="color:white;margin-left: 0em;">Colors</p>
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="playerColorR">
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="playerColorG">
        </div>


        <div style="width: 33%;"> <!--col2-->
            <img :src="getPlayerModelImagePath" alt="Player model image"
                style="max-width: 80%; display: block; margin: 0 auto; border: none;" />
            <input type="checkbox" v-model="highQualityModels" style="margin-left: 1.5em">
            <label :class="highQualityModels ? 'title-checked-style' : 'title-style'">High quality models</label>
        </div>


        <div style="width: 33%;"> <!--col3-->
            <p style="color:white;margin-left: 0em; margin-top: 0%;">Spraypaint image</p>
            <select v-model="sprayPaintImage" style="color:white;">
                <option v-for="(image, i) in sprayPaintImages" :key="i" :value="image">{{ image }}</option>
            </select>
            <img style="border: none; width: 37%; display: block; margin: 4% 0% 8% 0%" :src="sprayPaintImagePath"
                alt="Spraypaint image" />
            <select v-model="sprayPaintColor" style="color:white;">
                <option v-for="(color, i) in sprayPaintColors" :key="i" :value="color">{{ color }}</option>
            </select>
            <button style="display: block; position: absolute; bottom: 0; right: 0;"
                @click="toggleAdvanced">Advanced...</button>
        </div>
        <div v-if="showAdvanced" class="box" style="position: absolute; bottom: 20%; right: 0; padding: 10px;">
            <div class="box inset" style="overflow-y: scroll;">
                <p style="float: left; color:white;margin: 0;">Multiplayer decal limit</p>
                <input style="width: 30%; color:white; float: right;" type="text" v-model="decalLimit">
            </div>
            <button style="display: block; position: absolute; bottom: 0; right: 0;" @click="toggleAdvanced">Close</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MultiplayerMenu",
    components: {},
    computed: {
        // Compute the path of the player model image based on the playerModel value
        getPlayerModelImagePath() {
            const imagePath = require(`@/assets/${this.playerModel}.png`);
            console.log('Image path: ', imagePath);
            return imagePath;
        },
        sprayPaintImagePath() {
            return require(`@/assets/${this.sprayPaintImage}.png`);
        },
    },
    props: {},
    data() {
        return {
            playerName: "GreenBastard",
            decalLimit: 300,
            playerModels: [
                "Barney",
                "Gina",
                "Gman",
                "Gordon",
                "Helmet",
                "Hgrunt",
                "Recon",
                "Robo",
                "Scientist",
                "Zombie",
            ],
            playerModel: "Gordon",
            playerColorR: 10,
            playerColorG: 2,
            highQualityModels: false,
            sprayPaintImages: [
                "lambda",
                "skull",
                "smiley",
                "splatt",
                "tiki",
                "v_1",
                "devl1",
            ],
            sprayPaintImage: "lambda",
            sprayPaintColors: [
                "Red",
                "Green",
                "Blue",
                "Yellow",
                "Orange",
                "Purple",
                "White",
                "Black",
            ],
            sprayPaintColor: "Orange",
            showAdvanced: false, // Added property to track the visibility of the advanced element
        };
    },
    methods: {
        toggleAdvanced() {
            this.showAdvanced = !this.showAdvanced; // Toggle the visibility of the advanced element
        },

    }
}
</script>

<style scoped>
.flexboxrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.title-style {
    color: white;
    text-align: left;
}

.title-checked-style {
    color: #bcaf4f;
    text-align: left;
}
</style>