<template>
    <div class="flex-box">
        <div style="width: 50%;">
            <p style="color:white;margin-left: 0em;">Renderer</p>
            <select v-model="renderer" style="width: 60%; margin-bottom: 0.5em;color:white;">
                <option value="Software">Software</option>
                <option value="OpenGL">OpenGL</option>
            </select>


            <p style="color:white;margin-left: 0em;">Resolution</p>
            <select v-model="currentResolution" style="width: 60%; margin-bottom: 0.5em;color:white;">
                <option v-for="(res, i) in resolution" :key="i" :value="res">{{ res }}</option>
            </select>


            <p style="color:white;margin-left: 0em;">Display Mode</p>
            <select v-model="displayMode" style="width: 60%; margin-bottom: 1.5em;color:white;">
                <option value="Normal">Normal</option>
                <option value="Widescreen">Widescreen</option>
            </select>


            <p style="color:white;margin-left: 0.2em;">Brightness</p>
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="brightness" />
            <p style="margin-top: 3em; width: 200%; margin-left:0.2em" class="description-style">Note: changing video
                options will cause the game to exit and restart.</p>
        </div>


        <div style="width: 80%;margin-top: 2.5em;">
            <div v-for="(u, i) in cycleArray" :key="i">
                <input type="checkbox" v-model="u.isChecked">
                <label :class="u.isChecked ? 'title-checked-style' : 'title-style'">{{
                    u.description }}</label>
            </div>
            <p style="color:white;margin-left: 0.2em;margin-top: 5.7em;">Gamma</p>
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="gamma" />
        </div>
    </div>
</template>

<script>
export default {
    name: "VideoMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            cycleArray: [
                {
                    isChecked: false,
                    description: "Run in a window"
                },
                {
                    isChecked: false,
                    description: "Wait for vertical sync"
                },
                {
                    isChecked: false,
                    description: "Enable HD models if available"
                },
                {
                    isChecked: false,
                    description: "Allow custom addon content"
                },
                {
                    isChecked: false,
                    description: "Low video quality. Helps with slower video cards."
                },
            ],
            renderer: "OpenGL",
            resolution: [
                "640x480",
                "800x600",
                "1024x768",
                "1280x1024",
                "1600x1200",
                "1920x1080",
                "1920x1200",
                "2560x1440",
                "2560x1600",
                "3840x2160",
                "3840x2400",
                "4096x2160",
                "4096x2560",
            ],
            currentResolution: "1920x1080",
            displayMode: "Widescreen",
            brightness: 50,
            gamma: 80,
        };
    },
    methods: {}
}
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

.flex-box {
    display: flex;
    flex-direction: row;
}

.description-style {
    color: #9DA792;
    text-align: left;
    font-size: 0.9em;
}

.title-style {
    color: white;
    text-align: left;
}

.title-checked-style {
    color: #bcaf4f;
    text-align: left;
}
</style>