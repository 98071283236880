<template>
    <div style="display:flex; flex-direction: row;">
        <div style="width: 55%;">
            <p style="color:white;" class="checkbox-style">Sound effects volume</p>
            <input type="range" class="ticks checkbox-style" min="0" max="100" step="1" v-model="soundEffectsVolume"
                style="" />
            <p style="color:white; margin-top: 2em;" class="checkbox-style">MP3 volume *</p>
            <input type="range" class="ticks checkbox-style" min="0" max="100" step="1" v-model="mp3Volume" style="" />
            <p style="color:white; margin-top: 2em;" class="checkbox-style">HEV suit volume</p>
            <input type="range" class="ticks checkbox-style" min="0" max="100" step="1" v-model="hevSuitVolume" style="" />
            <p style="margin-top: 4em; margin-left: 3.3em; width: 130%" class="description-style">* MPEG Layer-3 playback
                supplied
                with the Miles
                Sound System from RAD Game Tools, Inc. MPEG Layer-3 audio compression technology licensed by Fraunhofer IIS
                and
                THOMSON multimedia.</p>
        </div>
        <div style="width: 45%;">
            <p style="color:white;margin-left: 0em;">Sound
                quality</p>
            <select v-model="soundQuality" style="width: 70%; margin-bottom: 2em;color:white;">
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "AudioMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            soundEffectsVolume: 100,
            mp3Volume: 80,
            hevSuitVolume: 50,
            soundQuality: "High",
        };
    },
    methods: {}
}
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

.checkbox-style {
    margin-left: 3.2em;
    text-align: left;
}

.description-style {
    color: #9DA792;
    margin-left: 2em;
    text-align: left;
    font-size: 0.9em;
    width: 33.3%;
}
</style>