<template>
    <div>
        <div class="box inset" style="height: 23em; overflow-y: scroll;">
            <table class="bindings-table-header">
                <tr v-for="(binding, index) in KeyboardBindings" :key="index">
                    <td :class="{ 'table-header': isActive(index) }">
                        <input type="text" v-model="binding[0]" :disabled="isActive(index)" />
                    </td>
                    <td :class="{ 'table-header': isActive(index) }">
                        <input type="text" v-model="binding[1]" :disabled="isActive(index)" />
                    </td>
                    <td :class="{ 'table-header': isActive(index) }">
                        <input type="text" v-model="binding[2]" :disabled="isActive(index)" />
                    </td>
                </tr>
            </table>
        </div>
        <button @click="resetBindings">Use Defaults</button>
        <button class="button-align-right">Clear Key</button>
        <button class="button-align-right">Edit key</button>
    </div>
</template>

<script>
export default {
    name: "KeyboardMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            activeIndices: [0, 14, 18, 33],
            KeyboardBindings: [
                ["MOVEMENT", "KEY/BUTTON", "ALTERNATIVE"],
                ["Move forward", "Up", "W"],
                ["Move back", "Down", "S"],
                ["Turn left", "Left", ""],
                ["Turn right", "Right", ""],
                ["Move left (strafe)", "A", ","],
                ["Move right (strafe)", "D", "."],
                ["Jump", "Space", ""],
                ["Duck", "Left Ctrl", ""],
                ["Swim up", "V", "ø"],
                ["Swim down", "C", "-"],
                ["Look up", "PageUp", ""],
                ["Look down", "PageDown", ""],
                ["Use items", "E", ""],
                ["COMMUNICATION", "KEY/BUTTON", "ALTERNATIVE"],
                ["Use voice communication", "K", ""],
                ["Chat message", "Y", ""],
                ["Team message", "U", ""],
                ["COMBAT", "KEY/BUTTON", "ALTERNATIVE"],
                ["Primary attack", "MOUSE1", "Return"],
                ["Secondary attack", "MOUSE2", ""],
                ["Reload weapon", "R", ""],
                ["Walk", "Left Shift", ""],
                ["Flashlight", "F", ""],
                ["Spray logo", "T", ""],
                ["Weapon category 1", "1", ""],
                ["Weapon category 2", "2", ""],
                ["Weapon category 3", "3", ""],
                ["Weapon category 4", "4", ""],
                ["Weapon category 5", "5", ""],
                ["Previous weapon", "Mouse Wheel Up", "å"],
                ["Next weapon", "Mouse Wheel Down", ""],
                ["Last weapon used", "Q", ""],
                ["MISCELLANEOUS", "KEY/BUTTON", "ALTERNATIVE"],
                ["Display multiplayer scores", "Tab", ""],
                ["Take screen shot", "F5", ""],
                ["Quick save", "F6", ""],
                ["Quick load", "F7", ""],
                ["Pause game", "Pause", ""],
                ["Quit game", "F10", ""],
            ],
            DefaultBindings: [
                ["MOVEMENT", "KEY/BUTTON", "ALTERNATIVE"],
                ["Move forward", "Up", "W"],
                ["Move back", "Down", "S"],
                ["Turn left", "Left", ""],
                ["Turn right", "Right", ""],
                ["Move left (strafe)", "A", ","],
                ["Move right (strafe)", "D", "."],
                ["Jump", "Space", ""],
                ["Duck", "Left Ctrl", ""],
                ["Swim up", "V", "ø"],
                ["Swim down", "C", "-"],
                ["Look up", "PageUp", ""],
                ["Look down", "PageDown", ""],
                ["Use items", "E", ""],
                ["Communication", "KEY/BUTTON", "ALTERNATIVE"],
                ["Use voice communication", "K", ""],
                ["Chat message", "Y", ""],
                ["Team message", "U", ""],
                ["Combat", "KEY/BUTTON", "ALTERNATIVE"],
                ["Primary attack", "MOUSE1", "Return"],
                ["Secondary attack", "MOUSE2", ""],
                ["Reload weapon", "R", ""],
                ["Walk", "Left Shift", ""],
                ["Flashlight", "F", ""],
                ["Spray logo", "T", ""],
                ["Weapon category 1", "1", ""],
                ["Weapon category 2", "2", ""],
                ["Weapon category 3", "3", ""],
                ["Weapon category 4", "4", ""],
                ["Weapon category 5", "5", ""],
                ["Previous weapon", "Mouse Wheel Up", "å"],
                ["Next weapon", "Mouse Wheel Down", ""],
                ["Last weapon used", "Q", ""],
                ["Miscellaneous", "KEY/BUTTON", "ALTERNATIVE"],
                ["Display multiplayer scores", "Tab", ""],
                ["Take screen shot", "F5", ""],
                ["Quick save", "F6", ""],
                ["Quick load", "F7", ""],
                ["Pause game", "Pause", ""],
                ["Quit game", "F10", ""],
            ],
        };
    },
    methods: {
        isActive(index) {
            return this.activeIndices.includes(index);
        },
        resetBindings() {
            this.KeyboardBindings = this.DefaultBindings.map((row) => [...row]);
        },
    }
}
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

td {
    color: white;
    padding: 0.2em;
    text-align: center;
    font-size: 0.9em;
    width: 33.3%;
}
</style>