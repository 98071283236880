<template>
    <div>
        <div class="flex-box">
            <div style="width: 30%; margin-top: 3em; margin-bottom: 4em;">
                <input type="checkbox" :disabled="contentLocked" v-model="contentLocked">
                <label :class="contentLocked ? 'title-checked-style' : 'title-style'">{{ contentLockDescription }}</label>

                <div v-if="contentLocked && !passwordEntered">
                    <input type="password" placeholder="Enter new password" v-model="password"
                        @keydown.enter="storePassword">
                </div>
                <div v-if="contentLocked && passwordEntered">
                    <input type="password" placeholder="Enter password" v-model="password" @keydown.enter="checkPassword">
                    <span v-if="showError" style="color: red;">Incorrect password. Please try again.</span>
                </div>
            </div>

            <div style="width: 80%; margin-top: 2em; margin-left: 3em;">
                <p style="width: 100%;" class="description-style">Press this button and enter password to disable visuals
                    inappropriate for younger players.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LockMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            contentLocked: false,
            contentLockDescription: "Content Lock",
            password: "",
            passwordEntered: false,
            showError: false,
            storedPassword: "my_password",
        };
    },
    methods: {
        checkPassword() {
            if (this.password === this.storedPassword) {
                this.passwordEntered = false;
                this.contentLocked = false;
                this.password = "";
                this.showError = false;
            } else {
                this.showError = true;
                this.password = "";
            }
        },
        storePassword() {
            this.storedPassword = this.password;
            this.password = "";
            this.passwordEntered = true;
        }
    },
};
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

.flex-box {
    display: flex;
    flex-direction: row;
}

.description-style {
    color: #9DA792;
    text-align: left;
    font-size: 0.9em;
}

.title-style {
    color: white;
    text-align: left;
}

.title-checked-style {
    color: #bcaf4f;
    text-align: left;
}
</style>