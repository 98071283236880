<template>
    <div class="flex-box">
        <div style="width: 50%;">
            <input type="checkbox" v-model="voiceIsChecked">
            <label :class="voiceIsChecked ? 'title-checked-style' : 'title-style'">{{
                voiceDescription }}</label>

            <p style="color:white;margin-left: 0.2em;margin-top: 1.5em;">Voice transmit volume *</p>
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="voiceTransmitVolume" />

            <img src="@/assets/voiceBoostTest.png" alt="My Image"
                style="width: 97%;height: 8%;object-fit: fill;margin-top: 3em;border: none;" />
            <div style="display: flex; flex-direction: column;">
                <button>Test Microphone</button>

                <div style="display: flex; align-items: center;margin-top: 1.5em;">
                    <input type="checkbox" v-model="boostIsChecked">
                    <label :class="boostIsChecked ? 'title-checked-style' : 'title-style'">{{ boostDescription }}</label>
                </div>
            </div>

            <p style="margin-top: 3em; width: 200%; margin-left:0.2em" class="description-style">* Uses Miles Sound
                System.<br>Copyright 1991-2001 by RAD Game Tools, Inc.</p>
        </div>


        <div style="width: 80%;margin-top: 2.15em;margin-left: 4em;">
            <p style="color:white;margin-left: 0.2em;;">Voice receive volume *</p>
            <input type="range" class="ticks" min="0" max="100" step="1" v-model="voiceReceiveVolume" />
        </div>
    </div>
</template>

<script>
export default {
    name: "VoiceMenu",
    components: {},
    computed: {},
    props: {},
    data() {
        return {
            voiceIsChecked: true,
            voiceDescription: "Enable voice in this game",
            voiceTransmitVolume: 100,
            boostIsChecked: true,
            boostDescription: "Boost microphone gain",
            voiceReceiveVolume: 100,
        };
    },
    methods: {}
}
</script>

<style scoped>
.button-align-right {
    float: right;
    margin-left: 2%;
}

.bindings-table-header {
    /* table-layout: fixed; */
    width: 100%;
    border-collapse: collapse;
}

.table-header {
    border: none transparent;
    text-decoration: transparent;
    background-color: transparent;
    border-style: hidden;
    outline: transparent;
    color: #bcaf4f;
    border-bottom: 0.2em solid #292f23;
    padding-top: 0.5em;
    font-size: 0.9em;
    width: 33.3%;
}

.flex-box {
    display: flex;
    flex-direction: row;
}

.description-style {
    color: #9DA792;
    text-align: left;
    font-size: 0.9em;
}

.title-style {
    color: white;
    text-align: left;
}

.title-checked-style {
    color: #bcaf4f;
    text-align: left;
}
</style>